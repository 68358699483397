<template>
	<section class="row">
		<div class="col-12">
			<b-form-textarea
				v-model="inputValue"
				class="insuranceTextarea mb-2"
				:disabled="disabled"
				:required="required"
				:state="isRequiredAndFilled"
				:placeholder="placeholder"
			></b-form-textarea>
		</div>
	</section>
</template>

<script>
import requiredFieldMixin from '../../../mixins/requiredFieldMixin'
export default {
	name: 'InputTextarea',
	props: { value: null, disabled: Boolean, required: Boolean, placeholder: { type: String, default: 'Kommentar' } },
	mixins: [requiredFieldMixin],
	computed: {
		inputValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
}
</script>

<style></style>
