<template>
	<section class="mb-5">
		<div class="row">
			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.resignationDate') }}</b>
					</div>
					<div class="col-12">
						<InputDate :disabled="disabled" v-model="employeeCondition.resignationDate" :required="!isAdminOrAdvisor"> </InputDate>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.comment') }}</b>
					</div>
					<div class="col-12">
						<InputComment v-model="employeeCondition.resignationComment" :disabled="disabled" />
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="row">
					<b>
						Indsæt medarbejderens privat email og telefonnummer, så vi har mulighed for at kontakte medarbejderen i forbindelse med rådgivning ved
						fratrædelse.</b
					>
				</div>
			</div>
			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('global.user.emailSecondary') }}</b>
					</div>
					<div class="col-12">
						<InputText v-model="employeeInfo.emailSecondary" :disabled="disabled" />
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('global.user.phoneSecondary') }}</b>
					</div>
					<div class="col-12">
						<InputPhone v-model="employeeInfo.phoneSecondary" :disabled="disabled" />
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import InputDate from '@/modules/global/atomComponents/InputDate.vue'
import InputText from '@/modules/global/atomComponents/InputText'
import InputComment from '@/modules/global/atomComponents/InputComment'
import InputPhone from '@/modules/global/atomComponents/InputPhone.vue'

export default {
	name: 'EmployeeResignation',
	components: {
		InputDate,
		InputText,
		InputComment,
		InputPhone
	},
	mounted() {},
	props: {
		value: Object,
		employeeInfo: Object,
		disabled: Boolean,
	},
	computed: {
		employeeCondition: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		isAdminOrAdvisor() {
			return this.$store.getters['auth/isAdmin'] || this.$store.getters['auth/isAdvisor']
		},
	},
}
</script>

<style></style>
