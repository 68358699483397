<template>
	<div>
		<b-form-input v-model="inputValue" :disabled="disabled" v-mask="'+##################'" :required="required" :placeholder="place"></b-form-input>
	</div>
</template>

<script>
import requiredFieldMixin from '../../../mixins/requiredFieldMixin'
import { mask } from 'vue-the-mask'

export default {
	name: 'UserTextInput',
	mixins: [requiredFieldMixin],
	directives: { mask },
	props: {
		value: String,
		disabled: {
			type: Boolean,
		},
		required: {
			type: Boolean,
		},
		place: {
			type: String,
		},
	},
	computed: {
		inputValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
}
</script>

<style></style>
