<template>
	<div>
		<b-form-input
			v-model="inputValue"
			:disabled="disabled"
			:required="required"
			:state="isRequiredAndFilled"
			:placeholder="placeholder"
			:type="type"
		></b-form-input>
	</div>
</template>

<script>
import requiredFieldMixin from '../../../mixins/requiredFieldMixin'

export default {
	name: 'UserTextInput',
	mixins: [requiredFieldMixin],
	props: {
		value: String,
		disabled: {
			type: Boolean,
		},
		required: {
			type: Boolean,
		},
		placeholder: {
			type: String,
		},
		type: String,
	},
	computed: {
		inputValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
}
</script>

<style></style>
