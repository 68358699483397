<template>
	<section class="container">
		<div class="row justify-content-end">
			<div class="col-auto">
				<BaseActionButton type="button" v-on:on-click="setEditable" v-if="$can('update', 'EmployeePolicy', 'EmployeeCondition')">
					<font-awesome-icon :icon="['far', 'edit']" class="icon" />
				</BaseActionButton>
			</div>
		</div>
		<b-form @submit.prevent="areYouSurePopup">
			<employee-resignation v-model="employeePolicy.employeeCondition" :employeeInfo="employeeInfo" :disabled="disabled" />
			<div class="row mb-5">
				<div class="col-12">
					<BaseActionButton class="btn-update" v-bind:clicked="clicked" v-bind:disabled="clicked" v-show="!disabled" type="submit" id="signupButton">
						{{ $t('global.update') }}
					</BaseActionButton>
				</div>
			</div>
		</b-form>
	</section>
</template>

<script>
import EmployeeResignation from '../components/EmployeeResignation.vue'
import Swal from 'sweetalert2'

export default {
	name: 'EmployeeResignation',
	components: {
		'employee-resignation': EmployeeResignation,
	},
	data() {
		return {
			clicked: false,
			disabled: true,
		}
	},
	async mounted() {
		if (this.editableRouter) {
			this.disabled = false
		}
		this.$parent.$emit('updateLoader')
	},
	methods: {
		setEditable() {
			this.disabled = !this.disabled
		},
		async areYouSurePopup() {
			Swal.fire({
				title: 'Er du sikker at du vil fratræde medarbejderen?',
				showCancelButton: true,
				confirmButtonText: 'Ja, fratræd medarbejderen',
				showLoaderOnConfirm: true,
				preConfirm: () => {
					try {
						return this.onSubmit()
					} catch (error) {
						console.log(error)
						Swal.showValidationMessage(`${error.message}`)
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			}).then((result) => {
				if (result.isConfirmed) {
					this.$router.push({
						name: 'CompanyEmployeeList',
						params: { companyId: this.companyId },
					})
				}
			})
		},
		async onSubmit() {
			this.clicked = true
			const policyId = this.employeePolicyId
			try {
				await this.$store.dispatch('userVuex/updateUser', {
					data: this.employeeInfo,
					id: this.employeeInfo.id,
				})
				await this.$store.dispatch('employeeVuex/updateEmployeePolicy', {
					id: policyId,
					employeeId: this.employeeInfo.id,
					companyId: this.companyId,
					data: this.employeePolicy,
				})
				this.toast('Success', 'En medarbejderens fratrædelse er nu registeret', true)
			} catch (e) {
				this.toast('Error', 'En fejl opstod under opdatering af medarbejder' + e, false)
			}
			this.clicked = false
		},
	},
	computed: {
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
		employeePolicyId() {
			return this.$store.getters['employeeVuex/employeePolicy'].id
		},
		editableRouter() {
			return this.$route.query.editable
		},
		companyId() {
			return this.$route.params.companyId
		},
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
		employeeInfo() {
			return this.$store.getters['employeeVuex/user']
		},
		employeeId() {
			return this.$route.params.employeeId
		},
	},
}
</script>

<style></style>
