const requiredFieldMixin = {
	computed: {
		isRequiredAndFilled() {
			if (this.required == true) {
				if (this.value) {
					return null
				} else if (this.value === '') {
					return false
				} else if (this.value == 0) {
					return null
				} else return false
			} else return null
		},
	},
}

export default requiredFieldMixin
